<template>
  <div class="container" ref="container">
    <img
      :src="src"
      :alt="alt"
      ref="image"
      :class="{ portrait: portrait, landscape: landscape }"
    />
  </div>
</template>

<script>
import ScrollMagic from "scrollmagic";

export default {
  props: {
    src: String,
    alt: String
  },

  data: () => {
    return {
      animationController: new ScrollMagic.Controller()
    };
  },

  methods: {
    setZoom: function() {
      new ScrollMagic.Scene({
        triggerElement: this.$refs.container,
        triggerHook: 0.9,
        offset: 50,
        reverse: true
      })
        .setClassToggle(this.$refs.container, "img-zoom-on-enter--visible")
        .addTo(this.animationController);
    }
  },

  computed: {
    landscape: function() {
      if (this.$refs.image == null) return true;
      return this.$refs.image.naturalHeight <= this.$refs.image.naturalWidth;
    },

    portrait: function() {
      if (this.$refs.image == null) return false;
      return this.$refs.image.naturalWidth < this.$refs.image.naturalHeight;
    }
  },

  mounted: function() {
    this.setZoom();
  },

  activated: function() {
    this.setZoom();
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

img {
  min-height: 100%;
  min-width: 100%;
  transform: scale(1.2);
  transition: all 0.8s ease-in-out;
}

img.portrait {
  width: 100%;
}

img.landscape {
  height: 100%;
}

.img-zoom-on-enter--visible img {
  transform: none;
}

.img-zoom-on-exit img {
  transform: scale(1.2);
}
</style>
