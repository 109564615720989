<template>
  <section class="content-container">
    <div class="block">
      <div class="block-background background" ref="background"></div>
      <div class="content">
        <div class="text-container" ref="body">
          <h3>{{ data.title[0].value }}</h3>
          <p v-html="data.body[0].value"></p>
        </div>
        <div class="image-container">
          <ImageZooming
            v-for="image in data.field_portrait_image"
            :key="image.url"
            :src="image.url"
            :alt="image.alt"
            class="image"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ImageZooming from "@/components/ImageZooming";
import ScrollMagic from "scrollmagic";
import { visualMixin } from "@/mixins/visualMixin.js";
import { httpMixin } from "@/mixins/httpMixin.js";

export default {
  components: {
    ImageZooming
  },

  props: {
    data: Object
  },

  mixins: [visualMixin, httpMixin],

  mounted: function() {
    new ScrollMagic.Scene({
      triggerElement: this.$refs.background,
      offset: 0,
      triggerHook: 0.8,
      reverse: false
    })
      .setClassToggle(this.$refs.background, "block-background--visible")
      .addTo(this.animationController);

    new ScrollMagic.Scene({
      triggerElement: this.$refs.body,
      offset: 50,
      triggerHook: 0.75,
      reverse: false
    })
      .setClassToggle(this.$refs.body, "text-container--visible")
      .addTo(this.animationController);
  }
};
</script>

<style scoped>
.content-container {
  text-align: left;
  margin: 0 -16px 24px;
}

.block {
  border: none;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100vw;
  padding: 24px 16px 36px;
  box-sizing: border-box;
}

.block-background {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 0;
  right: 0%;
  transform: translateX(-100vw);
  transition: transform 0.8s ease-in-out;
}

.block-background--visible {
  transform: none;
}

.content {
  position: relative;
}

.image-container {
  display: grid;
  gap: 24px;
  grid-template-columns: 100%;
  margin-top: 48px;
}

.image {
  max-height: 50vh;
}

.text-container {
  text-align: left;
  position: relative;
  transition: clip-path 1s ease-in-out;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  padding-right: 60px;
}

.text-container--visible {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

@media screen and (min-width: 768px) and (orientation: landscape) {
  .content-container {
    padding-right: 60px;
    margin: 0;
  }

  .block {
    width: 50%;
    padding-top: 7vh;
    margin-bottom: 180px;
  }

  .block-background {
    width: 50vw;
    right: 50%;
  }

  .image-container {
    width: 200%;
    min-height: 30vh;
    grid-template-columns: 1fr 1fr 1fr;
    transform: translateY(180px);
    margin-top: -100px;
  }
}
</style>
